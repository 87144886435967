//Colors
//$brand-color-1: #006CAA;
$brand-color-1: #4facff;
$brand-color-2: #f28701;
$brand-color-3: #183D92;
//$brand-color-4: #eaf3e3;
$brand-color-4: #183D92;
//$brand-color-5: #eaf3e3;
$brand-color-5: #183D92;


.brand-color-1{color: $brand-color-1;}
.brand-color-2{color: $brand-color-2;}
.brand-color-3{color: $brand-color-3;}
.brand-color-4{color: $brand-color-4;}

.brand-bg-1{background-color: $brand-color-1;}
.brand-bg-2{background-color: $brand-color-2;}
.brand-bg-3{background-color: $brand-color-3;}
.brand-bg-4{background-color: $brand-color-4;}
//$brand-color-4: set_hsb($brand-color-3,$s:5%,$b:97%);
//$brand-color-4: change_color($brand-color-3, $lightness:95%);
//$brand-color-4: adjust_color($brand-color-3, $lightness:28%);
//$brand-color-5: adjust_color($brand-color-3, $lightness:20%, $saturation:-25%);
$brand-primary : $brand-color-1;

$bg-1 : #393b2f;

$hr-border : $brand-color-1;

// GRID
$grid-columns : 24;
$body-bg:#fff;

// BREAK POINTS
//$screen-sm : 620px;
//$container-sm: 580px;


// NAVBAR

$navbar-default-color:#Fc0;
$navbar-default-bg:#FFF;
$navbar-default-link-color:$brand-color-1;
$navbar-default-link-hover-color:$brand-color-3;
$navbar-default-link-active-color:$brand-color-2;
$navbar-default-link-active-bg:#FFF;
$navbar-default-link-hover-bg:transparent;

//$navbar-bg : none;
//$navbar-inverse-bg:change_hsb($brand-color-1,$b:75%,$s:100%);
$navbar-inverse-bg:$brand-color-3;
//$navbar-inverse-link-color : rgba(#FFF,0.7);
$navbar-inverse-link-color : $brand-color-1;
$navbar-inverse-link-hover-color: #FFF;
$navbar-inverse-link-hover-bg:transparent;
$navbar-inverse-link-active-color: $brand-color-2;
$navbar-inverse-link-active-bg: #FFF;

$nav-bg: rgba(#eee,.95);
$nav-link: #999;
$nav-text:#999;
$nav-hover:#333;
$nav-active:#000;
//$navbar-inverse-link-active-bg:background: linear-gradient(to bottom, lighten($brand-color-2, 20%) 0%, $brand-color-2 100%);
//$dropdown-bg:$brand-color-2;
$dropdown-link-color : $brand-color-1;
$dropdown-link-hover-color : #FFF;
$dropdown-link-hover-bg : $brand-color-3;
$dropdown-link-active-color : #FFF;
$dropdown-link-active-bg : $brand-color-1;




$zindex-dropdown : 1500;
//$nav-tabs-active-link-hover-bg : red;

// TYPE
$headings-font-family : "Raleway", sans-serif;
//$headings-font-family : "Titillium Web", sans-serif;
//$headings-font-family : "Catamaran", sans-serif;
//$headings-font-family : "Comfortaa", sans-serif;
//$headings-font-family : "Marvel", sans-serif;
$headings-color : $brand-color-1;
$headings-line-height : 1.3;
$headings-font-weight : 400;

//$font-family-base : "Raleway", sans-serif;
//$font-family-base : "Open Sans", sans-serif;
$font-family-base : "Raleway", sans-serif;
$font-family-nav : "Raleway", sans-serif;

$font-size-base: 15px;
$text-color:#333;
$line-height-base : 1.8;
$link-color : $brand-color-1;
$link-hover-color : $brand-color-1;
$link-hover-decoration: underline;
$blockquote-border-color:$brand-color-1;
//$link-hover-color : $brand-color-2;
//$line-height-computed : 2;

// FORMS
$input-border : change_hsb($brand-color-1,$s:20%,$b:95%);
//$input-color : darken($brand-color-5,20%);
//$input-border : lighten($brand-color-3,5%);
$input-border-focus : $brand-color-1;
//$input-bg:lighten($brand-color-3,5%);
//$input-color:#FFF;

$input-group-addon-bg : $brand-color-1;
$input-group-addon-border-color : $brand-color-1;


$label-color:#FFF;


$border-radius-base : 0px;
$border-radius-large : 0px;
$border-radius-small : 0px;

// BUTTONS
.btn{
  //font-family: $headings-font-family;
  //text-transform: uppercase;
  //font-weight: bold;
}
$btn-font-weight:500;
//$btn-default-color : change_hsb($brand-color-3,$b:60%, $s:40%);
//$btn-default-border : change_hsb($brand-color-3,$b:90%, $s:10%);
//$btn-default-bg : change_hsb($brand-color-3,$b:99%,$s:1%);

//$navbar-height:60px;

// PANELS
$panel-default-heading-bg : change_hsb($brand-color-1,$b:98%,$s:10%);
$panel-default-border : change_hsb($brand-color-1,$b:98%,$s:20%);

// TOOLTIP
$tooltip-bg:adjust_hsb($brand-color-1,$b:-20%);
//$zindex-modal:1100;
//$tooltip-max-width: 400px;

// TABLES
//$table-bg-accent:change_hsb($brand-color-3, $b: 99%, $s: 1%);
//$table-border-color: change_hsb($brand-color-3, $b: 90%, $s: 8%);

$thumbnail-bg:#FFF;

// PAGINATION
$pagination-color:$brand-color-1;
$pagination-bg: transparent;
$pagination-hover-bg:$brand-color-1;
$pagination-border:rgba(#000,0);
$pagination-hover-color:#fff;
$pagination-hover-border:rgba(#000,0);
$pagination-active-color:$brand-color-2;
$pagination-active-bg:#FFF;
$pagination-active-border:$brand-color-2;
$pagination-disabled-color:rgba($brand-color-1,.5);
$pagination-disabled-border:rgba(#000,0);
$pagination-disabled-bg:transparent;

// BREADCRUMB
$breadcrumb-bg:transparent;
$breadcrumb-padding-vertical:0;
$breadcrumb-padding-horizontal:0;
