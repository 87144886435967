.dropdown-menu {
  //min-width: 180px;
  .dropdown-menu {
    border-radius: 0 $border-radius-base $border-radius-base 0;
  }
  > li {
    > a {
      padding: 5px 15px;
    }
    &.dropdown > a {
      padding-right: 25px;
    }
  }
}

.navbar-wrapper {
  position: relative;
  margin: 0 -15px;
  z-index: 20;
  //line-height: 22px;
  .dropdown-menu > li > a {
    font-size: 13px;
    font-weight: 600;
  }
  > .container {
    padding-right: 0;
    padding-left: 0;
  }
  .flag {
    margin-right: 7px;
    position: relative;
    box-shadow: 0 1px 1px rgba(#000, .2);
    top: -1px;
  }
  .fa, .el {
    margin-right: 5px;
  }
  .navbar {
    z-index: auto;
    //font-family: "Roboto Condensed", sans-serif;
    font-family: $font-family-nav;
    //font-family: "Catamaran", sans-serif;
    //font-family: "Kanit", sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0;
    //background: $brand-color-1;
    //text-shadow: 0 -1px 0 darken($navbar-inverse-bg, 15%);
    //box-shadow: 0 1px 0 lighten($navbar-inverse-bg, 15%) inset /*, 0 2px 10px rgba(0, 0, 0, 0.3)*/;
    .fa, .el {
      font-size: 14px;
    }
    .fa-caret-down {
      font-size: 12px;
    }

    .dropdown-icon {
      line-height: 0;
      &::before {
        content: '\f0d7';
        font-family: FontAwesome;
        font-size: 12px;
        display: inline-block;
        font-style: normal !important;
        font-weight: normal !important;
      }
    }
    .navbar-brand {
      padding: 10px 0;
      height: auto;
      > img {
        max-height: 50px;
        width: auto;
      }
    }
    .navbar-header {

    }
  }
  .navbar .container {
    width: auto;
  }
  .dropdown-menu {
    //background: darken($navbar-inverse-bg, 5%);
    text-transform: none;
    //font-family: $font-family-base;
    //font-size: 13px;
    box-shadow: 0 6px 12px change_hsb($brand-color-5, $b: 40%, $s: 100%, $a: 0.1);
    > li > a {
      text-shadow: none;
      //color: lighten($navbar-inverse-bg, 45%);
    }
    > li > a:hover,
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      //color: #FFF;
      //background: darken($navbar-inverse-bg, 10%);
    }
  }
  .navbar-toggle {
    //background: adjust_hsb($brand-color-1, $b: -10%);
    border-color: $brand-color-1;
    margin: 13px 0;
    padding: 14px 12px;
    .icon-bar {
      background-color: $brand-color-1;
    }
    &:hover, &:focus {
      //background: adjust_hsb($brand-color-1, $b: -15%);
      background: transparent;
    }
  }

}

@media (max-width: $screen-xs-max) {

  .navbar-header {
    min-height: 70px;
    margin: 0 -15px;
    padding: 0 15px;
    background: $navbar-default-bg;
    background: linear-gradient(to bottom,
            #FFF 0%,
            $panel-default-heading-bg 100%);
  }
  .navbar-collapse {
    margin: 0 -15px;
  }
  .navbar-inverse .navbar-nav > .open > a,
  .navbar-inverse .navbar-nav > .open > a:hover,
  .navbar-inverse .navbar-nav > .open > a:focus {
    background: none;
  }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    background-color: $brand-color-1;
    color: #FFF;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .nav-user {
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar {
    border-bottom: 3px solid $brand-color-1;

    .open {
      background: none;
      > a {
        background: none;
      }
      > .dropdown-toggle > .fa {
        transform: rotate(180deg);
      }
      > .dropdown-toggle > .dropdown-icon::before {
        content: '\f0d8';
      }
      .dropdown-menu {
        padding: 0;
        //background: rgba(0, 0, 0, 0.1);
        border-radius: 0;
        > li:first-child > a {
          box-shadow: 0 7px 6px -6px rgba(0, 0, 0, 0.3) inset;
        }
        > li:last-child > a {
          box-shadow: 0 -19px 20px -20px rgba(0, 0, 0, 0.15) inset;
        }
        .dropdown-menu {
          a {
            padding-left: 35px;
          }
        }
      }
    }
    li {
      &.active > a {
        //background: rgba($brand-color-5, 0.5) !important;

        &.dropdown-toggle {
          background: none !important;
        }
      }
      > .dropdown-toggle {
        background: none !important;
        box-shadow: none !important;
      }
    }
  }
  .lang-menu {
    display: block;
    position: relative;
    background: none;
    float: none;
    padding: 0;
    border: none;
    border-top: 1px solid rgba($brand-color-1, .3);
    li {
      float: left;
      width: 33.333%;
      text-align: center;
      > a {
        //font-family: "Roboto Condensed", sans-serif;
        //font-size: 14px;
        text-transform: uppercase;
        text-shadow: 0 -1px 0 rgba(#000, 0.2);
        padding: 12px 0;
        font-weight: bold;
        //color: #FFF;
        /*
                &:hover,&:focus{
                  background: adjust_hsb($brand-color-1, $b: -10%);
                }*/
      }
    }
  }
  .navbar-nav li.dropdown {
    > a {
      padding-right: 36px;
    }
    > a.dropdown-toggle {
      position: absolute;
      top: 0;
      right: 0;
      padding-left: 15px;
      padding-right: 15px;
      background: none;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        bottom: 10px;
        width: 0;
        border-left: 1px solid rgba($brand-color-1, 0.3);
        border-right: 1px solid rgba(255, 255, 255, 0.15);
      }
    }
  }
}

@media (min-width: $screen-sm) {
  .navbar-default {
    box-shadow: 0 2px 8px 2px change_hsb($brand-color-1,$b:96%,$s:8%);
    /*background: linear-gradient(to top,
            $navbar-default-bg 0%,
            adjust_hsb($navbar-default-bg, $b: -1%, $s: 10%) 100%
    );
    background: linear-gradient(to bottom,
            $brand-color-3 0%,
            adjust_hsb($brand-color-3, $b: -10%, $s: 10%) 100%
    );*/
  }
  .navbar-wrapper {
    //margin: 0 0 -25px;
    //float: right;
    //margin: 25px 0 0;
    margin: 0;
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
    .navbar {
      padding-right: 0;
      padding-left: 0;
      border-bottom: none;
      //border-radius: $border-radius-base;
    }
    .fa-caret-down {
      margin: 0 0 0 5px;
    }
    .dropdown-icon::before {
      margin: 0 0 0 5px;
    }
    .navbar-nav {
      > li {
        > a {
          //color: $brand-color-1;
        }
        &.dropdown {
          > a {
            padding-right: 15px;
          }
        }
      }
    }
    /*
    .navbar-nav > li.pid_2 > a {
      border-radius: $border-radius-base 0 0 $border-radius-base;
    }
    .navbar-right > li:last-child > a {
      border-radius: 0 $border-radius-base $border-radius-base 0;
    }
    .navbar-right > li:last-child.open > a {
      border-radius: 0 $border-radius-base 0 0;
    }*/
    .navbar-right {
      margin-right: 0;
    }
    .separator-v {
      display: block;
    }
  }
  .navbar .shortcuts {
    position: absolute;
    right: 0;
    //font-size: 12px;
    top: -85px;
    //z-index: 1002;
    box-shadow: 0 4px 5px -1px change_hsb($brand-color-3, $b: 60%, $s: 100%, $a: 0.2);
    //box-shadow: 0 4px 5px -1px rgba(#000,.25);
    //background: linear-gradient(to bottom,
    //        change_hsb($brand-color-3, $b: 82%, $s: 25%) 0%,
    //        change_hsb($brand-color-3, $b: 78%, $s: 30%) 100%
    //);
    //background: change_hsb($brand-color-3, $b: 78%, $s: 30%);
    background: $brand-color-1;

    > ul {
      //height: 45px;
      height: 51px;
      position: relative;
      > li {
        > a {
          color: #FFF !important;
          text-shadow: 0 -1px 0 rgba(#000, 0.25);
          padding: 12px 10px;
          .fa {
            margin-right: 5px;
          }
          &:hover {
            background: change_hsb($brand-color-3, $s: 100%, $b: 40%, $a: 0.2) !important;
          }
        }
        .dropdown-menu {
          border-color: $brand-color-2;
          padding: 0;
          a {
            padding: 10px 15px;
          }
        }
      }
    }
    .open > a {
      background: change_hsb($brand-color-3, $s: 100%, $b: 40%, $a: 0.2) !important;
    }

  }
  .separator-v {
    width: 0;
    height: 51px;
    border-left: 1px solid change_hsb($brand-color-1, $b: 60%, $s: 100%, $a: 0.15);
    border-right: 1px solid rgba(#FFF, 0.15);
    //display: none;
  }
  .navbar .lang-menu {
    //position: absolute;
    //top: -120px;
    //font-size: 13px;
    //right: 0;
    li {
      a {
        padding: 4px 8px;
        text-shadow: none;
        //color: $link-color;
        //border-radius: 2px;
        //font-weight: normal;
        /*
                &:hover,&:focus{
                  color:#FFF;
                  background: change_hsb($brand-color-3,$b:80%,$s:30%);
                }*/
      }
      &.active {
        a, a:hover {
          //background: change_hsb($brand-color-3,$b:70%,$s:50%);
          //box-shadow: none;
          //box-shadow: 0 3px 3px -1px rgba(31, 94, 153, 0.2);

        }
      }
      .tooltip {
        text-transform: none;
      }
    }
  }
  .dropdown-menu {
    border: none;
    border-top: 3px solid $brand-color-2;
    border-radius: 0;
    &.user-menu{
      min-width: 0 !important;
      max-width: none !important;
      width: 100% !important;
    }
    li {
      &.dropdown {
        > a {
          &::after {
            content: '\f0da';
            font-family: FontAwesome;
            font-size: 10px;
            position: absolute;
            top: 7px;
            right: 7px;
            //text-shadow: 0 -1px 0 rgba(#000, 1);
          }
        }
        /*
                &.open > a, &.open > a:focus {
                  color: $dropdown-link-hover-color;
                  background: $dropdown-link-hover-bg;
                }
                &.active > a {
                  background: $dropdown-link-active-bg;
                }*/
      }
    }
    .dropdown-menu {
      border: none;
      border-radius: 0;
      top: 0;
      left: 100%;
      margin: 0;
      padding: 0;
    }
  }
  html.touch {
    .navbar-nav {
      a.dropdown-toggle {
        background: none;
      }
      li.dropdown {
        > a {
          padding-right: 50px;
          &::after {
            display: none;
          }
          > .fa-caret-down {
            display: none;
          }
          > .dropdown-icon {
            display: none;
          }
        }
        > a.dropdown-toggle.visible-xs {
          display: block !important;
          position: absolute;
          top: 0;
          right: 0;
          padding-left: 15px;
          padding-right: 15px;
          > .fa-caret-down {
            display: inline;
            margin: 0;
          }
          > .dropdown-icon {
            display: inline-block;
            &::before {
              margin: 0;
            }
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 15px;
            bottom: 15px;
            width: 0;
            border-left: 1px solid rgba(#000, 0.15);
            border-right: 1px solid rgba(#FFF, 0.3);
          }
        }
        li {
          a {
            padding: 10px 15px;
          }
          &.dropdown {
            > a {
              padding-right: 50px;
              color: $brand-color-2;
              &.dropdown-toggle {
                &::before {
                  top: 10px;
                  bottom: 10px;
                }
              }
            }
          }
          .dropdown-icon::before {
            content: '\f0da';
          }
        }
      }
    }
  }
  .navbar-nav .open > a {
    color: #FFF !important;
  }
  .navbar-inverse .navbar-nav > .open > a,
  .navbar-inverse .navbar-nav > .open > a:hover,
  .navbar-inverse .navbar-nav > .open > a:focus {
    //box-shadow: 0 1px 0 lighten($brand-color-2, 25%) inset;
    //text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    //background: darken($brand-color-1,8%);
  }

  .navbar-inverse .navbar-nav > .active > a,
  .navbar-inverse .navbar-nav > .active > a:hover,
  .navbar-inverse .navbar-nav > .active > a:focus {
    //box-shadow: 0 1px 0 lighten($brand-color-1, 40%) inset;
    //text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    //background: $brand-color-4;
    //background: linear-gradient(to bottom, lighten($brand-color-1, 20%) 0%, $brand-color-1 100%);
  }
}

@media (min-width: $screen-md) {
  .navbar .shortcuts {
    //font-size: 14px;
    > ul > li > a {
      padding: 12px 15px;
    }
  }
}