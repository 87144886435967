.sort-nav {
  //background: #f5f5f5;
  font-family: $font-family-nav;
  //background: mix(#FFF,$navbar-default-bg,30%);
  background: $panel-default-heading-bg;
  border: 1px solid $panel-default-border;

  //border: 1px solid #DDD;
  padding: 3px 3px 3px 10px;
  margin: 0 0 20px;
  //border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  //text-transform: uppercase;
  .title {
    color:change_hsb($brand-color-1,$s:30%,$b:60%);

  }
  .btns {
    float: right;
    a {
      color: change_hsb($brand-color-1,$s:30%,$b:60%);
      display: inline-block;
      padding: 0 10px;
      text-decoration: none !important;
      .icon {
        display: inline-block;
        font-size: 14px;
        //margin-left: 5px;
        width: 1.28571429em;
        text-align: center;
        //position: relative;
        //vertical-align: middle;
        &::before, &::after {
          content: '\f175';
          font-family: FontAwesome;
          font-weight: normal !important;
        }
        &::after {
          content: '\f176';
        }

      }
      &:hover {
        color: $brand-color-3;
      }
      &.active {
        background: $brand-color-2;
        color: #FFF;
        border-radius: 3px;
        &:hover {
          background: darken($brand-color-2, 5%);
        }
        .icon {
          &::after {
            display: none;
          }
          &::before {
            content: '\f160';
          }
          &.alpha::before {
            content: '\f15d';
          }
          &.num::before {
            content: '\f162';
          }
        }
        &.desc {
          background: $brand-color-1;
          &:hover{
            background: darken($brand-color-1,10%);
          }
          .icon {
            &::before {
              content: '\f161';
            }
            &.alpha::before {
              content: '\f15e';
            }
            &.num::before {
              content: '\f163';
            }
          }
        }
      }
      /*&.desc {
        .icon {
          &::before {
            content: '\f15e';
          }
          &.num::before {
            content: '\f163';
          }
        }
      }*/
    }
  }

}