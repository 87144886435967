.calendar-container {
  padding: 5px;
  background: change_hsb($brand-color-1, $b: 97%, $s: 3%);
  border: 1px solid change_hsb($brand-color-1, $b: 90%, $s: 8%);
  margin: 0 0 10px;
}

table.calendar {
  border: none;
  margin: 0;
  th, td {
    text-align: center;
    padding: 8px 0 !important;
  }
  th, td.blank {
    border: none !important;
    background: transparent;
  }
  td {
    background: #FFF;
    color: #999;
    //border-color: change_hsb($brand-color-1, $b: 60%, $s: 70%) !important;
    border-color: change_hsb($brand-color-1, $b: 90%, $s: 8%) !important;

    &.event {
      //border-color:$brand-color-1 !important;
      a {
        text-decoration: none !important;
        color: #FFF;
        background: $brand-color-1;
        display: block;
        margin: -9px 0;
        padding: 9px 0;
        transition:all .2s ease;
        &:hover {
          background: $brand-color-3;
        }
      }
    }
  }
}

table.calendar-control {
  margin: 0 0 5px;
  td {
    text-align: center;
    border: none !important;
    .fa {
      font-size: 14px;
    }
    &.pad0 {
      padding: 0 !important;

    }
  }
}