.orange-bg {
  background: #dc811b;
  color: #FFF;
  padding: 3px 5px;
  display: inline-block;
}

.grey-bg {
  background: #435465;
  color: #FFF;
  padding: 3px 5px;
  display: inline-block;
}