ul.documents {
  li {
    padding-left: 1rem;
    display: block;
    position: relative;
    /*&::before {
      content: '\f016 ';
      display: inline-block;
      position: absolute;
      left: 0;
      font: {
        family: FontAwesome;
        size: 14px;
      }
    }*/
  }
}