$lightbox-bg : rgba(#FFF, 0.7);
$lightbox-border : 5px solid #fff;
$lightbox-shadow : 0 0 20px change_hsb($brand-color-3,$b:85%,$s:10%);
$lightbox-btn-bg : change_hsb($brand-color-3,$b:40%,$s:60%);

.lightbox {
  cursor: pointer;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0); /* IE Fallback (Solid Colour) */
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAIElEQVQ4T2NkYGDYDMRkA8ZRAxhGw4BhNAyA+WAYpAMAIFgLQfO9BoEAAAAASUVORK5CYII=);
  background: $lightbox-bg;
  filter: none !important;
  z-index: 9997;
}

.lightbox img {
  display: block;
  position: absolute;
  border: $lightbox-border;
  box-shadow: $lightbox-shadow;
  border-radius: 1px;
}

body.blurred > * {
  filter: blur(2px);
  transform: translate3d(0, 0, 0);
}

.lightbox-loading {
  background: url(loading.gif) center center no-repeat;
  width: 31px;
  height: 31px;
  margin: -16px 0 0 -16px;
  position: absolute;
  top: 48%;
  left: 50%;
}

.lightbox-caption {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 9998;
  background: #000;
  background: rgba(0, 0, 0, 0.7);
}

.lightbox-caption p {
  margin: 0 auto;
  max-width: 70%;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}
.lightbox__nav{
  //perspective:800px;
}

.lightbox__button {
  position: absolute;
  z-index: 9999;
  color:#FFF !important;
  background: $lightbox-btn-bg;
  width: 32px;
  height: 64px;
  line-height: 64px;
  opacity: 0.5;
  transition: all 0.3s;
  text-align: center;
  &::before {
	display: inline-block;
	content: "\f054";
	font-family: FontAwesome;
  }
  &:hover{
	color:#FFF;
	opacity: 1;
	transform: translateZ(100px);
	text-decoration: none;
  }
}


.lightbox__close {
  right: 10px;
  top: 10px;
  background-image: url('close.png');
  height:32px;
  line-height: 32px;

  &::before{
	content:"\f00d";
  }
}

.lightbox__nav--next {
  right: 10px;
  top: 48%;
  text-decoration: none;

}

.lightbox__nav--prev {
  left: 10px;
  top: 48%;
  &::before {
	content: "\f053";
  }
}
