.staticRight {
  //padding-top: 30px;
  >*:nth-child(3n+1){
    clear: left;
  }
  .cardzz {
    border: 1px solid #DDD;
    background: #f5f5f5;
    background: #f5f5f5;
  }
  .statEl {
    background: change_hsb($brand-color-1, $b: 97%, $s: 3%);
    line-height: 1.4;
    //color: lighten($brand-color-5, 60%);
    padding: 10px 15px;
    margin: 0 0 10px;
    //font-size: 13px;
    border: 1px solid change_hsb($brand-color-1, $b: 90%, $s: 8%);

    .bg-pic {
      margin: -10px -15px;
      padding: 10px 15px;
      background-image: url('../images/i_cards/bg-1.jpg');
      background-position: center;
      background-size: cover;
      h2, h3, h4, h5, h6, p {
        color: #FFF;
      }
      &-1 {
        p {
          color: #dbdf9c;
        }

      }
      &-2 {
      background-image: url('../images/i_cards/bg-2.jpg');

      }
    }
    .pic {
      margin: -10px -15px 5px;
    }
    .well {
      background: #FFF;
      border: none;
      padding: 10px 20px;
    }
    .small {
      line-height: 1.4;
    }
    > h2, > h3, > h4, > h5, > h6 {
      //color: #FFF;
      //text-transform: uppercase;
      //text-shadow: 0 -1px 0 adjust_hsb($brand-color-5,$s:15%,$b:-15%);
      //font-weight: 300;

    }

  }
}
