.tag-clouds {
  margin: 30px 0 50px;
}
.tag-cloud {
  font-size: .9em;
  a {
    margin-right: 5px;
    .badge {
      font-family: sans-serif;
      //font-weight: normal;
      background-color: $brand-color-4;
      margin-left: 3px;
      font-size: .8em;
    }
    &:hover {
      .badge {
        background-color: $brand-color-1;
      }
    }
  }
}
fieldset.tag-cloud{
  border: 1px solid $brand-color-4;
  padding: 5px 15px 15px;
  margin: 30px 0;
  legend{
    //display: inline-block;
    margin: 0;
    color: $brand-color-1;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    width: auto;
    border: none;
    margin-bottom: 0;
    .fa{
      font-size: 14px;
    }
  }
}