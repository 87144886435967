.register-form {
  background: #FFF;
  padding: 1px 30px 30px;
  margin: 0 0 30px;
  h3, h4 {
    font-weight: bold;
    font-size: 18px;
    padding: 0 0 5px;
    margin: 40px 0 20px;
    position: relative;
    border-bottom: 1px solid $brand-color-2;
  }
  h4 {
    font-size: 14px;
    border-bottom: 1px solid #DDD;
    margin: 30px 0 10px;
    text-transform: none;
  }
}

@media screen and (min-width: $screen-sm) {
  .register-form {
    h4 {
      margin-left: 20px;
    }
  }
}