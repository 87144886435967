.header-img {
  //background-image: url(attr(data-bg));
  background-position: center;
  background-size: cover;
  padding: 60px 30px;
  margin: -30px 0 30px;
  h2 {
    margin: 0;
    font-size: 10px;
    font-weight: bold;
    font-family: $font-family-base;
    text-transform: uppercase;
    a {
      display: inline-block;
      letter-spacing: .2em;
      padding: 3px 10px;
      background: #FFF;
      color: $brand-color-1;
      text-decoration: none !important;
      &:hover{
        text-decoration: none;
        color: #FFF;
        background: $brand-color-1;
      }
    }

  }
  h1 {
    display: inline-block;
    background: rgba($brand-color-3, .9);
    //border-radius: 8px 8px 8px 0;
    //border: 2px solid #FFF;
    padding: 5px 15px;
    color: #FFF;
    margin: 0 0 10px;
    position: relative;
    &::after {
      content: "";
      //z-index: 0;
      border-bottom: 50px solid rgba($brand-color-2, .8);
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      position: absolute;
      bottom: 0;
      width: 0;
      background-color: transparent;
      transform-origin: 0 50px;
      transform: scale(1, .3) rotate(45deg);
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .header-img {
    margin: 0px 0 30px;
  }
}