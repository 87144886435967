.ppi{
  font-family: FontAwesome;
  font-size: 14px;
  font-weight: normal !important;
  font-style: normal !important;
  &::before{
    display: inline-block;
    vertical-align: middle;
  }
  &.ppi-25::before,&.ppi-38::before{
    content: '\f19d';
  }
  &.ppi-28::before{
    content: '\f12e';
  }
  &.ppi-29::before{
    content: '\f0e6';
  }
  &.ppi-30::before{
    content: '\f130';
  }
  &.ppi-31::before{
    content: '\f008';
  }
  &.ppi-32::before{
    content: '\f02d';
  }
}