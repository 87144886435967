.accountMenu{
  a{
    display: block;
    padding: 3px 8px;
    border-radius: 2px;

    &:hover{
      background: #f5f5f5;
      text-decoration: none !important;
      color: $brand-color-4;
    }
  }
  
  li.active{
    a{
      background: $brand-color-3;
      color:#FFF;
    }
  }
}
.my-collections{
  margin: 0;
  >li{
    padding: 4px 10px;
    &+li{
      border-top: 1px solid $panel-default-border;
    }
    &:hover{
      background-color: mix($brand-color-4,#FFF,10%);
    }
  }
}