 .side-pics{
   display: none;
   position: relative;
   .side-pic{
     position: absolute;
     width: 250px;
     &.left{
       right:100%;
     }
     &.right{
       left:100%;
     }
   }
 }
 @media screen and(min-width: 1700px){
   .container.main-content{
     min-height: 800px;
   }
   .side-pics{
     display: block;
   }
 }