.side-menu-toggler {
  margin-top: 0;
  padding: 5px 10px;
  background: change_hsb($brand-color-1, $b: 100%, $s: 8%);
  cursor: pointer;
  border-radius: 3px;
  > .icon {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    float: right;
    &::after {
      content: '\f0d7';
    }
  }
  &.on {
    > .icon {
      &::after {
        content: '\f0d8';
      }
    }
  }
}

.side-menu /*,.cat-prods*/
{
  display: none;
  font-family: $font-family-nav;
  font-size: 13px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  //border-bottom: 15px solid #FFF;
  li {
    &:not(:last-child) {
      border-bottom: 1px solid rgba($brand-color-2, .2);
    }

    a {
      color: $brand-color-3;
      display: block;
      padding: 6px 10px;
      &:hover, &:focus {
        //background: adjust_hsb($brand-color-4,$b:-2%,$s:6%,$a:0.3);
        background: rgba($brand-color-2, 0.1);
        //color:adjust_hsb($brand-color-3,$b:-20%,$s:20%);
        //color: change_hsb($brand-color-3, $b: 69%, $s: 67%);
        text-decoration: none;
      }
      &.dropdown-toggle {
        &:hover, &:focus {
          //color: $brand-color-2;
        }
      }
    }
    &.dropdown {
      > a:first-child {
        padding-right: 24px;
      }
    }
  }
  > li {
  }
  ul {
    border-top: 1px solid rgba($brand-color-2, .2);
    padding: 0;
    //background: rgba(#f5faff, 0.25);
    background: change_hsb($brand-color-2, $b: 100%, $s: 5%);
    > li {
      //border-color: rgba($brand-color-2,.5);
    }
  }
  li {
    list-style: none;
    position: relative;
    &.active {
      > a {
        color: #FFF;
        background: $brand-color-2;
        //font-weight: bold;
        //color: #FFF;
        &.dropdown-toggle {
          background: none;
        }
      }
    }
  }
  li li {
    a {
      padding-left: 20px;

    }
    &:first-child a {
      box-shadow: 0 7px 6px -6px change_hsb($brand-color-2, $s: 40%, $b: 100%, $a: 0.5) inset;
    }
    &:last-child a {
      box-shadow: 0 -7px 6px -6px change_hsb($brand-color-2, $s: 40%, $b: 100%, $a: 0.5) inset;
    }
  }
  .submenu {
    display: none;
  }
  .on > .submenu {
    display: block;
  }
  .dropdown-toggle {
    color: $brand-color-2;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
  }
  .dropdown-icon {
    display: inline-block;
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: normal;
    font-style: normal !important;
    line-height: 0;
    transform: translate(0, 0);
    &::before {
      //vertical-align: middle;
      content: '\f0d7';

    }
  }
  .on > .dropdown-toggle > .dropdown-icon {
    &::before {
      content: '\f0d8';
    }
  }
}

.touch .side-menu {
  li {
    a {
      padding: 10px 10px;
      &.dropdown-toggle {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
    &.dropdown {
      > a:first-child {
        padding-right: 36px;
      }
    }
  }
}

.cat-prods {
  margin: 0;
  display: block;
  border-top: 15px solid #FFF;
  h4 {
    font-size: 15px;
    color: #FFF;
    margin: 0 0 10px;
    text-shadow: 0 -1px 0 adjust_hsb($brand-color-5, $s: 15%, $b: -15%);
    background: adjust_hsb($brand-color-5, $s: 8%, $b: -4%);
    padding: 6px 15px;
    border-bottom: 2px solid adjust_hsb($brand-color-5, $s: 15%, $b: -15%);

  }
  ul {
    border: none;
    background: none;
    a {
      padding: 6px 10px 6px 15px;
    }
  }
}

@media (min-width: $screen-md) {
  .side-menu-toggler {
    padding: 0;
    background: none;
    cursor: auto;
    > .icon {
      display: none;
    }
  }
  .side-menu {
    display: block !important;
    height: auto !important;
  }
}
