.news-li{
  font-size: 14px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 3px 1px -2px rgba(#000,0.1);
  .pic{
    margin: -20px -20px 10px;
  }
  .date{
    margin: 12px 0;
    font-size: 13px;
    span {
      display: inline-block;
      background: $brand-color-2;
      font-weight: 600;
      padding: 0 5px;
      //border-radius: 3px;
      &::before{
        content: '\f133';
        font-family: FontAwesome;
        font-size: 14px;
        font-weight: normal;
        margin: 0 6px;
      }
    }
  }
  .btn{
    .fa{
      font-size: 14px;
    }
  }
}