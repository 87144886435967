header {
  background: #FFF;
  //background: #FFF url(../images/i_layout/bg-header.png) no-repeat top fixed;
  //background-size: 100vw auto;
  background: linear-gradient(to top,
          #FFF 0,
    //change_hsb($brand-color-1,$b:98%,$s:18%) 100%
          change_hsb($panel-default-heading-bg,$b:98%,$s:15%) 100%
    //change_hsb($brand-color-1,$b:100%,$s:10%) 140px,
    //#FFF 140px
  );
  position: relative;
  z-index: 100;
  margin: 0 0 30px;
  //box-shadow: 0 3px 5px rgba(#000, .15);

  .brand {
    display: inline-block;
    padding: 5px 0;
    img {
      max-height: 40px;
    }
  }
  .logged-as{
    font-weight: 500;
    color: $brand-color-3;
    padding: 10px 15px;
    text-transform: none;
  }
}

@media screen and (min-width: $screen-sm) {
  header {
    background: linear-gradient(to top,
            #FFF 0,
            //change_hsb($brand-color-1,$b:98%,$s:18%) 100%
            change_hsb($panel-default-heading-bg,$b:98%,$s:15%) 100%
            //change_hsb($brand-color-1,$b:100%,$s:10%) 140px,
            //#FFF 140px
    );
    .container {
      background-image: url('../images/i_layout/header.png');
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
    }
    //box-shadow: 0 -25px 0 #FFF inset;
    .brand {
      padding: 25px 0 30px;
      img {
        max-height: 70px;
      }
    }
    .logged-as{
      position: absolute;
      padding: 0;
      top:-28px;
      right:0;
    }
    /*&::before{
      content:'';
      position: absolute;
      z-index: -1;
      background: red;
      top: 0;
      left: 0;
      right: 0;
      bottom: 25px;
      background: linear-gradient(to top,
              change_hsb($brand-color-1, $b: 95%, $s: 7%) 0%,
              change_hsb($brand-color-3, $b: 100%, $s: 0%) 100%
      );
    }*/
  }

}