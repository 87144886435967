[data-gfx="slick"] {
  padding: 0;
  img {
    width: 100%;
  }
}

.slick-slider {
  &.slick-dotted {
    margin-bottom: 0;
  }
  .slick-slide {
    position: relative;
    .caption {
      position: absolute;
      top: 50%;
      left: 6%;
      right: 6%;
      transform: translateY(-35%);
      transition: all .5s ease .5s;
      opacity: 0;
      padding: 0 15px 30px;
      > * {
        //max-width: 75%;

      }
    }
    &.slick-current {
      .caption {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
  }
}
.slick-arrow{
  text-shadow: 0 0 10px rgba(#000,.3),0 0 60px rgba(#000,.8);
}

.slick-dots {
  bottom: 8px;
  li {
    margin: 0;
    button {
      &:before {
        color: #FFF;
        opacity: .5;
        font-size: 6px;
      }
    }
    &.slick-active {
      button {
        &:before {
          color: #FFF;
          opacity: 1;
          text-shadow: 0 0 5px rgba(#000, .5);
        }
      }
    }

  }
}
.slick-arrow {
  width: 30px;
  height: 40px;
  background: $brand-color-1;
  color: #FFF;
  background: #FFF;
  border: 1px solid $panel-default-border;
  color: $panel-default-border;
  &:before {
    font-family: FontAwesome;
    color: inherit;
    text-shadow: none;
  }
  &:hover,&:focus{
    background: $brand-color-1;
    border-color: $brand-color-1;
    color: #FFF;
  }
}
.slick-prev {
  left: -16px;
  border-radius: 0 3px 3px 0;

  &:before {
    content: '\f053';
  }
}
.slick-next {
  right: -16px;
  border-radius: 3px 0 0 3px;
  &:before {
    content: '\f054';
  }
}