.generic-list-li {
  .pic{
    margin-top: 1.5rem;
  }
  & + .generic-list-li {
    margin-top: 4rem;
    > div:last-child {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -1rem;
        left: $grid-gutter-width * .5;
        width: 20px;
        height: 3px;
        background: $brand-color-2;
      }
    }
  }
}