//$footer-bg: change_hsb($brand-color-1,$s:5%,$b:100%);
$footer-bg: $panel-default-heading-bg;
$footer-color: change_hsb($brand-color-1, $b: 70%, $s: 50%);
//$footer-link: lighten($footer-bg,50%);
//$footer-link: change_hsb($brand-color-2, $s: 20%);
$footer-link: $brand-color-3;
$footer-link-hover: darken($footer-link, 5%);
footer {
  color: adjust_hsb($brand-color-3, $b: -40%);
  color: change_hsb($brand-color-2, $s: 30%);
  color: $footer-color;
  //border-top: 2px solid $brand-color-1;
  //background: $brand-color-3;
  //background: $footer-bg;
  background: linear-gradient(to bottom, mix($footer-bg,#FFF, 15%) 0%, $footer-bg 100%);
  font-weight: 500;
  //box-shadow: 0 2px 2px rgba(#000,.1) inset;
  //display: none;

  padding: 0 0 30px;
  //$footer-bg: darken($brand-color-2, 2%);
  margin: 80px 0 0;
  //background: $footer-bg;
  //color: #999;
  //color: $footer-links;
  >div{
    padding-top: 30px;
  }
  h4 {
    color: $brand-color-3;
    text-transform: uppercase;
    letter-spacing: .14em;
    font-weight: bold;
    font-size: 13px;
    //border-bottom: 2px solid rgba($footer-links,.2);
    border-bottom: 2px solid change_hsb($brand-color-1, $b: 92%, $s: 40%);
    //border-bottom: 2px solid $brand-color-4;
    //border-bottom: 2px solid lighten($footer-bg,8%);
    //border-bottom: 2px solid #CCC;
    padding-bottom: 10px;

    .fa {
      font-size: 14px;
    }
    a {
      padding: 10px 0;
      margin: -10px 0;

      color: #FFF;
      display: block;
      transition: all .2s ease;
      &:hover {
        background: $brand-color-1;
        background: rgba(#000, .2);
        color: #FFF;
        padding: 10px;

        text-decoration: none;
      }
    }
  }
  nav {
    .fa, .el {
      display: none;
    }
  }
  hr {
    border-color: $footer-color;
    margin: 0 0 5px;
  }
  .fa:not(.fa-at) {
    margin-right: 5px;
  }
  font-size: 13px;
  a {
    color: $footer-link;
    &:hover {
      color: $footer-link-hover;
      //color: #FFF;
    }
  }
  //box-shadow: 0 3px 3px #eaeaea inset;
  //border-top: 3px solid #dde7f0;
  .copyright {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 2px solid change_hsb($brand-color-1, $b: 92%, $s: 20%);
    //color: lighten($footer-bg, 20%);
    a {
      //color: lighten($footer-bg, 30%);
      &:hover {

      }
    }
  }
  .social-links {
    position: relative;
    padding: 10px 0;
    margin: 15px 0;
    list-style: none;
    border: 1px solid mix($footer-color, $footer-bg, 30%);
    border-width: 1px 0;
    //text-align: center;
    li {
      display: inline;
      & + li {
        margin-left: 3px;
      }
      a {
        //border: 2px solid #999;
        color: $brand-color-3;
        background: $footer-color;
        .fa {
          font-size: 18px;
          margin: 0;
          line-height: 1.3;
        }
      }
      &.title {
        //position: absolute;
        //display: block;
        //text-align:left;
        //background: $footer-bg;
        //width: 100%;
        //top: -12px;
        //left: 0;
        //transform: translate3D(0,-10px,0);
        span {
          font-size: 10px;
          text-transform: uppercase;
          //padding: 0 5px;
        }
      }
    }
    .btn {
      transition: all .3s ease;
      &:hover {
        &.btn-facebook {
          background: #3B5998;
          border-color: #3B5998;
          color: #FFF;
        }
        &.btn-twitter {
          background: #1DA1F2;
          border-color: #1DA1F2;
          color: #FFF;
        }
        &.btn-linkedin {
          background: #0077B5;
          border-color: #0077B5;
          color: #FFF;
        }
      }
    }
  }
}

.footer-nav {
  .col-sm-4 {
    margin-bottom: 15px;
    &:nth-child(3n+1) {
      clear: left;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding-left: 10px;
    }
    a {
      //color: #999;
      color: change_hsb($brand-color-2, $s: 20%);

      position: relative;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
      &::before {
        content: '\f0da';
        position: absolute;
        top: 1px;
        left: -9px;
        display: block;
        font-family: FontAwesome;
        font-size: 10px;
      }
    }
    &.v-trunc {
      position: relative;
      //margin-bottom: 5px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 60px;
        background: linear-gradient(to bottom, rgba($panel-footer-bg, 0) 0%, $footer-bg 100%);
        pointer-events: none;
        transition: height 1s ease;
      }
      &.v-on {
        &::after {
          height: 0;
        }
      }
    }
    & + .toggler {
      font-size: 10px;
      font-weight: 900;
      text-transform: uppercase;
      display: inline-block;
      cursor: pointer;
      color: $brand-color-1;
      &:hover {
        color: #fff;
      }
      .plus, .minus {
        letter-spacing: .2em;
        &::before {
          content: '\f0d7';
          font-family: FontAwesome;
          font-weight: normal;
          margin-right: 5px;
        }
      }
      .minus {
        &::before {
          content: '\f0d8';
        }
      }

    }
  }
}

@media (min-width: $screen-md) {
  footer {
    >div {
      background-image: url(../images/i_layout/header.png);
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
    }
  }
}