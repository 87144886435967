form.product.form-inline {
  .form-group, .form-group-sm {
    display: block;
    margin-bottom: 16px;
  }
  select {
    width: 100%;
    max-width: 100%;
  }
  .options {
    text-align: left;
    margin-bottom: 16px;
    font-size: 13px;
    border-bottom: 1px dotted rgba($brand-color-3, .3);

    .radio {
      display: block;
      border-top: 1px dotted rgba($brand-color-3, .3);

      label {
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
      }
      input[type=radio] {
        & + span {
          &::before {
            background: #FFF;
          }
        }
      }
      & + .radio {

      }
    }
  }
}

textarea.limited.form-control {
  border-radius: $input-border-radius $input-border-radius 0 0;
  position: relative;
  &:focus {
    z-index: 2;
  }
}

label.control-label.required:after {
  font-family: Arial, Helvetica, sans-serif;
}

.textareaStatus {
  font-size: 12px;
  color: #999;
  text-shadow: 0 1px 0 #FFF;
  background: $input-group-addon-bg;
  padding: 2px 10px;
  border-radius: 0 0 $input-border-radius $input-border-radius;
  border: 1px solid $input-border;
  border-top: none;
  z-index: -1;
  .charCounter {
    color: #888;
    font-weight: bold;
  }
}

.text-left {
  text-align: left !important;
}

.input-group-addon {
  //color: change_hsb($brand-color-3, $s: 30%, $b: 70%);
  color: #FFF;
}

.form-control:focus {
  box-shadow: none;
}

.has-error label.control-label.required {
  &::after {
    content: '\f071';
    font-family: FontAwesome;
    margin-left: 5px;
  }
}

.form-captcha {
  .input-group-addon {
    background-color: $brand-color-3;
  }
  img {
    border-right: 1px solid $brand-color-3;
  }
}

.form-checkbox, .form-radio {
  $check-color: $brand-color-1;
  $check-border: $brand-color-2;
  .radio, .checkbox {
    label {
      padding-left: 22px;
      input[type=radio], input[type=checkbox] {
        display: none;
        & + span {
          position: relative;
          &::before {
            content: '\f00c';
            font-family: FontAwesome;
            color: rgba($check-color, 0);
            position: absolute;
            overflow: visible;
            top: 2px;
            left: -22px;
            display: inline-block;
            width: 14px;
            height: 14px;
            background: rgba(#000, .05);
            box-shadow: 0 0 0 2px rgba(#000, .15) inset;
            font-size: 18px;
            line-height: 10px;
            text-indent: -1px;
            transition: color .2s ease;
          }
        }
        &:checked {
          & + span {
            &::before {
              //content: '\f00c';
              //font-family: FontAwesome;
              color: rgba($check-color, 1);

            }
          }
        }
      }
      input[type=radio] {
        & + span {
          &::before {
            content: '';
            border-radius: 50%;
          }
          &::after {
            content: '';
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba($check-color, 0);
            top: 6px;
            left: -18px;
            transition: background .2s ease;

          }
        }
        &:checked {
          & + span {
            &::after {
              background: rgba($check-color, 1);
            }
          }
        }
      }
      &:hover {
        input[type=radio], input[type=checkbox] {
          & + span {
            &::before {
              box-shadow: 0 0 0 2px rgba(#000, .25) inset;
            }
          }
        }
      }
      &:active {
        input[type=radio], input[type=checkbox] {
          & + span {
            &::before {
              box-shadow: 0 0 0 2px $check-border inset;
            }
          }
        }
      }
    }
  }
}