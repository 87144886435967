.panel {
  &-default {
    > .panel-heading {
      color: change_hsb($brand-color-1, $b: 50%, $s: 50%);
      a.delete-panel {
        position: relative;
        margin: -4px 10px 0 0;
        background-color: transparent;
        color: $brand-color-1;
        border-color: rgba($brand-color-1, .4);
        &:hover {
          border-color: $brand-color-1;
        }
      }
    }
  }
  .panel-title {
    font-size: 18px;
    font-weight: 500;
    .fa {
      //vertical-align: middle;
      font-size: 14px;
      margin-right: 5px;
    }
    .ppi {
      transform: translateY(-2px);
    }
  }
  a.panel-title {
    display: block;
    padding: 6px 15px;
    margin: -10px -15px;
    &:hover,&:focus{
      text-decoration: none;
      background: $brand-color-3;
      color: #FFF;
    }
  }
}