.thumb-gallery{
  font-size: 0;
  margin: 3rem 0;
  >a{
    //font-size: unset;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 130px;
    height: 132px;
    line-height: 120px;
    padding: 5px;
    text-align: center;
    border:1px solid $brand-color-1;
    margin-right: -1px;
    margin-bottom: -1px;
    &:hover{
      border-color: $brand-color-2;
      z-index: 1;
    }
    img{
      vertical-align: middle;
    }
  }
}