.agenda-ul {
  //margin-top: 20px;
  > * {
    margin-bottom: 30px;
  }
}

.agenda-li {
  font-size: 14px;
  background: #FFF;
  padding: 20px 15px;
  box-shadow: 0 3px 1px -2px rgba(#000,.1);
  .header{
    margin: -20px -15px 15px;
    padding: 8px 15px;
    padding-right: 8px;
    background: $brand-color-2;
  }
  .date{
    font-size: 12px;
    //letter-spacing: .2em;
    font-weight: 600;
    //color: #888;
    &::before{
      content: '\f133';
      font-family: FontAwesome;
      font-size: 14px;
      font-weight: normal;
      margin-right: 6px;
    }
  }
  .group-link{
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em;
    display: inline-block;
    float: right;
    color: #FFF;
    background: $brand-color-1;
    border: 0px solid #CCC;
    //line-height: 14px;
    vertical-align: middle;
    padding: 3px 5px;
    margin: 0;
    opacity: .7;
    transition: all .3s ease;
    &:hover{
      //color: #666;
      //border-color: #666;
      text-decoration: none;
      opacity: 1;
    }
  }
  h3{
    margin: 10px 0;
  }
  .pic{
    margin: 10px 0;
    text-align: center;
  }
}