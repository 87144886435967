
.basket-steps {
  margin: 0 0 50px;
  //font-family: "Raleway", sans-serif;
  ul {
    display: table;
    position: relative;
    list-style: none;
    width: 100%;
    padding: 0;
    text-align: center;
    margin: 0 0 15px;
    > li {
      counter-increment: bstep;
      //font-family: "Roboto Condensed",sans-serif;
      color: change_hsb($brand-color-1, $b: 70%, $s: 20%);
      display: table-cell;
      width: 20%;
      border-bottom: 2px solid change_hsb($brand-color-1, $b: 90%, $s: 8%);
      border-left: none;
      padding: 5px 0;
      &:first-child {
        //border-left: 1px solid #EEE;

      }

      &::before {
        content: counter(bstep);
        //float: left;
        font-size: 18px;
        display: inline-block;
        //font-family: 'Roboto', Arial, Helvetica, sans-serif;
        border: 2px solid change_hsb($brand-color-1, $b: 94%, $s: 6%);
        background: change_hsb($brand-color-1, $b: 98%, $s: 1%);
        border-radius: 50%;
        width: 35px;
        height: 35px;
        line-height: 31.5px;
        //color: #999;
      }
      &.active {
        color: $brand-color-1;
        border-color: $brand-color-2;
        > span {
          display: block;
        }
        &::before {
          //font-weight: bold;
          background: $brand-color-1;
          border-color: darken($brand-color-1, 10%);
          color: #FFF;
        }

      }
      > span {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        text-align: center;
        padding: 10px 0 0;
        line-height: 1.3;
      }
    }
  }
}

table.basket, table.price {
  //color: change_hsb($brand-color-3, $b: 55%, $s: 45%);
  //border-color: $brand-color-3;
  td, th {
    vertical-align: middle !important;
    //border-color: change_hsb($brand-color-3, $b: 90%, $s: 8%) !important;
  }
  th {
    color: change_hsb($brand-color-3, $b: 55%, $s: 45%);
  }

  .thumb {
    max-width: 64px;
  }
  input.qty {
    float: left;
    width: 70px;
    border-radius: 3px 0 0 3px;
  }
}

.basket-box {
  //font-family: 'Roboto Condensed';
  .amount {
    float: right;
  }
}

.transporters-list {
  text-align: center;
  margin-bottom: 20px;
}

.transporters-list-title {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.transporter-link {
  display: inline-block;
  max-width: 120px;
  border: 1px solid #CCC;
  img {
    opacity: .6;
  }
  &:hover {
    border-color: $brand-color-1;
    img {
      opacity: 1;
    }
  }
  &.active {
    border-color: $brand-color-3;
    img {
      opacity: 1;
    }
  }
}

.payment-choice {
  margin-top: 30px;
  input[type="image"]{
    max-width: 100%;
    margin-bottom: 20px;
    padding: 30px;
    border: 1px solid $brand-color-1;
    &:hover{
      border-color: $brand-color-2;
    }
  }
  > div {
    text-align: center;
  }
  form{
  }
  @media (min-width: $screen-md) {
    margin: 30px 100px;
  }
}

@media (min-width: $screen-sm) {
  .basket-steps {
    font-size: 13px;
    ul > li {
      padding: 5px 10px;
      > span {
        display: block;
        position: relative;
      }
    }
  }
}