@import "pnotify.core";
@import "pnotify.buttons";

$stripe-color: rgba(#FFF, .05);
.ui-pnotify {
  //animation: pnotifyFadein .4s cubic-bezier(0, .8, .2, 1);
  cursor: default;
  //background-color: #444;
  background-image: linear-gradient(-45deg, $stripe-color 25%, transparent 25%, transparent 50%, $stripe-color 50%, $stripe-color 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  animation: pnotifyFadein .4s cubic-bezier(0, .8, .2, 1), progress-bar-stripes .8s linear infinite /*paused*/;
  &.loading {
    //animation-play-state: running;
    .ui-pnotify-container {
      //background-color: transparent;
    }

  }
  &.countdown{
    //&::before{
    .timeline{
      content: '';
      display: block;
      height: 4px;
      background: rgba(#FFF,.2);
      position: absolute;
      margin-left: 8px;
      top: 0;
      left: 0;
      right: 0;
      animation: pnotify-countdown 6s linear forwards;
      animation-duration: attr(data-delay);
      transition:opacity 0.8s;
    }
    //&:hover::before{
    &:hover .timeline{
      animation: none;
      opacity: 0;
      transition:none;
      //height: 0;
      //animation-play-state: initial;
    }
  }
  .ui-pnotify-container {
    //color: #CCC;
    border-color: rgba(#000, .5);
    border-width: 0;
    border-left: 6px solid #000;
    //background-color: #444;
    transition: background-color .75s /*0.4s*/, border-color 0.5s, color 0.5s;
    //transition: all 0.5s;

    .ui-pnotify-title, .fa {
      //color: #FFF;
    }
    &::before{
      content: '';
      display: block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-left: none;
      border-right-color: #000;
      position: absolute;
      margin-top: -5px;
      top: 50%;
      right: 100%;
      transition: border-right-color 0.5s;
    }

    &.alert-info {
      border-color: $brand-info;
      &::before{
        border-right-color: $brand-info;
      }
    }
    &.alert-success {
      border-color: $brand-success;
      &::before{
        border-right-color: $brand-success;
      }
    }
    &.alert-warning {
      border-color: $brand-warning;
      &::before{
        border-right-color: $brand-warning;
      }
    }
    &.alert-danger {
      border-color: $brand-danger;
      &::before{
        border-right-color: $brand-danger;
      }
    }
  }
}


.ui-pnotify .ui-pnotify-shadow {
  box-shadow: 0 4px 3px -3px rgba(#000, 0.4);
}

.ui-pnotify-icon {
  padding-top: 4px;
  .fa {
    width: 1.28571429em;
    text-align: center;
  }
}

@keyframes pnotifyFadein {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes pnotify-countdown {
  from {
    left: 0;
  }
  to {
    left:100%;
  }
}