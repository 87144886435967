ul.guestbook-comments {
  list-style: none;
  margin: 30px 0;
  ul {
    list-style: none;
  }
  .fa{
    width: 28px;
    font-size: 14px;
    margin-left: -28px;
    text-align: center;
  }
  h4{
    .date{
      //font-style: italic;
      color: #999;
      font-size: 13px;
      font-family: $font-family-base;
      font-weight: normal;
      &::before{
        display: none;
      }
    }
  }
  > li {
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px;
    margin: 0 0 20px;
  }
}