body.pid_012{

}
.home-services {
  text-align: center;
  font-size: 14px;
  margin: 20px 0 50px;
  .service {
    padding: 20px 30px 30px;
    color: change_hsb($brand-color-2, $s: 30%);
    h4 {
      color: #fff;
      font-weight: 300;
      font-size: 22px;
      .fa {
        display: block;
        font-size: 56px;
        margin-bottom: 8px;
        color: $brand-color-1;
      }
    }
    a {
      display: inline-block;
      color: $brand-color-1;
      border: 2px solid $brand-color-1;
      padding: 2px 16px;
      border-radius: 16px;
      font-weight: 600;
      transition: all .2s ease;
      //text-transform: uppercase;
      //font-size: 10px;
      //line-height: 1.6em;
      &:hover {
        text-decoration: none;
        background: #FFF;
        border-color: #FFF;
        color: $brand-color-1;
      }
    }
  }
}
.home-video{
  //padding: 40px 0;
}
.home-testimonials{
  padding: 20px 0 50px;
}
.home-quotes {
  position: relative;
  float: none;
  margin: 20px auto;
  padding-left: 50px;
  padding-right: 50px;
  //border: 1px solid red;

  &::before, &::after {
    //content: open-quote;
    content: '“';
    position: absolute;
    line-height: 0;
    top: 40px;
    left: -10px;
    font-family: Georgia, Times New Roman, Times, serif;
    z-index: -1;
    font-size: 100px;
    color: $brand-color-2;
  }
  &::after {
    //content: close-quote;
    content: '„';
    top: auto;
    left: auto;
    right: -10px;
    bottom: 40px;
  }
  .slick-dots {
    bottom: -24px;
    line-height: 0;
    li {
      button:before {
        color: #BBB;
      }
      &.slick-active button:before {
        color: $brand-color-2;
        text-shadow: none;
      }
    }
  }
}

.home-quote {
  padding: 0;
  font-size: 21px;
  line-height: 1.4em;
  //margin: 0 50px;
  //text-align: center;
  i, em {
    font-family: Georgia, Times New Roman, Times, serif;
  }
  p:last-child:not(:first-child):not(:empty) {
    font-size: 15px;
    margin-bottom: 0;
    i, em {
      font-family: inherit;
    }
    &::before {
      content: '';
      display: inline-block;
      height: 2px;
      width: 20px;
      margin-right: 10px;
      background: #CCC;
      vertical-align: middle;
    }
  }

}

.home-emailing {
  background: #f3f3f3 url('../images/i_layout/coffie-paper.jpg') center;
  background-size: cover;
  padding: 20px 0 50px;
}

.home-social-feeds {
  padding: 40px 0;
  iframe {
    width: 100% !important;
    //height: auto !important;
    max-height: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .item {
    border: 1px solid #e5e5e5;
    padding: 15px 30px;
    margin-bottom: 20px;
  }
}

.home-press {
  padding: 40px 0;
  font-size: 14px;
  background: #f3f3f3 url('../images/i_layout/newspaper.jpg') center;
  background-size: cover;
  .item {
    box-shadow: 0 2px 0 rgba(#000, .05);
    //border: 1px solid #e5e5e5;
    padding: 15px 30px;
    margin-bottom: 20px;
    background: #FFF;
    //border-radius: 3px;
    .date {
      font-weight: bold;
      letter-spacing: .1em;
      &::before {
        content: '\2015';
        margin-right: 5px;
      }
    }
    .abstract {

    }
  }
}

section {
  &.bg-services{
    background: $brand-color-3 url('../images/i_layout/services.jpg') center;
    background-size: cover;
  }
  > .section-title {
    height: 20px;
    margin: 0 auto;
    text-align: center;
    //line-height: 0;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: .5em;

    span {
      text-align: center;
      transform: translateY(-50%);
      position: relative;
      //line-height: 1em;
      text-transform: uppercase;
      color: #FFF;
      background: darken($brand-color-2, 3%);
      text-shadow: 0 -1px 0 darken($brand-color-2, 10%);
      display: inline-block;
      border-radius: 20px;
      padding: 8px 2.2em 8px 2.7em;
    }
  }
}

.slick-prev {
  left: 10px;
  z-index: 1;
}

.slick-next {
  right: 10px;
  z-index: 1;
}

.slider-caption {
  padding: 40px 0 30px;
  margin-bottom: 50px;
  box-shadow: inset 0 -3px 0 rgba(#000, .3);

  .padH {
    padding: 0 8%;
  }
  //border-radius: 0 0 6px 6px;
  h2 {
    font-weight: 900;
    font-size: 4em;
    margin-top: 0;
  }
  h4 {
    text-transform: none;
    font-weight: 600;
    margin-top: 0;
  }

}

@media screen and (min-width: $screen-sm) {
  .slider-caption {
    padding: 40px 0 30px;
    &.what {
      min-height: 30vh;
    }
    .padH {
      padding: 0 4%;
    }
  }

}

@media screen and (min-width: $screen-lg) {

}
