.art-ul {
  > .col-sm-8 {
    &:nth-child(3n+1) {
      clear: left;
    }
  }
}

.price {
  display: inline-block;
  white-space: nowrap;
  padding: 0 8px;
  background: #FFF;
  border: 2px solid $brand-color-1;
  color: $brand-color-1;
  border-radius: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  &.old {
    text-decoration: line-through;
    opacity: .7;
    font-size: 11px;
    border-width: 1px;
  }
  &.promo {
    background: $brand-success;
    color: #FFF;
    border-color: darken($brand-success, 10%);
  }
  & + .price {
    margin-left: 5px;
  }
}

.new {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.8;
  padding: 0 5px;
  border-radius: 10px;
  text-decoration: none !important;
  background: $brand-color-2;
  border: 2px solid darken($brand-color-2, 6%);
  font-size: 9px;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFF;

}

.art-li.product .pic, .side-pic {
  .new {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.art-li {
  &.product {
    position: relative;
    overflow: hidden;
    border: 1px solid $panel-default-border;
    margin-bottom: 10px;
    transition: border-color .3s ease;

    .pic {
      position: relative;

    }
    h4 {
      font-size: 18px;
      font-weight: 600;
    }
    .desc {
      padding: 10px;
      font-size: 13px;
      .abstract {
        * {
          font-family: $font-family-base !important;
          color: $brand-color-3 !important;
        }
      }
      .qty, .options {
        display: none;
      }
      .btn-round {
        border-radius: 0;
        padding: 6px 12px;
        font-size: $font-size-base;
        line-height: 27px;
      }
    }

    &:hover {
      border-color: $brand-color-1;
    }
  }
  &.blog {
    border-bottom: 1px solid #DDD;
    padding-bottom: 30px;
    .pic {
      display: block;
      text-align: center;
      margin: 0 0 20px;
    }
  }
}

.alsoBought {
  //padding: 0 10px !important;

}

.art-cat {
  .desc {
    min-height: 85px;
  }
}

.art-li-h {
  margin: 0 5px 15px !important;
  position: relative;
  //background: mix(#FFF,$brand-color-1,90%);

  .pic {
    //position: absolute !important;
    //top: 0;
    //left: 0;
    //width: 100%;
    //height: 100%;
  }
  .desc {
    margin-bottom: 15px;
  }
}

.alsoBought-li {
  padding: 0 5px;
  .art-li.product {
    margin: 0;
    border-color: #FFF;
    &:hover {
      border-color: $brand-color-1;

    }
  }
}

.date {
  font-weight: bold;
  margin: -8px 0 5px;
  font-size: 12px;
  &::before {
    content: '\f133';
    font-family: FontAwesome;
    font-size: 14px;
    font-style: normal !important;
    margin-right: 5px;
    font-weight: normal !important;
  }
}

.art-item {
  margin: 0 0 30px;
  .product-desc {
    font-family: $font-family-base !important;
    * {
      font-family: $font-family-base !important;
    }
  }
  .side-pic {
    position: relative;
    border: 1px solid $panel-default-border;
    //padding: 5px;
    box-shadow: 0 1px 1px rgba(#000, 0.05);
    text-align: center;
    margin-bottom: 20px;
    > img {
      max-width: 50%;
    }
    .info {
      padding: 15px;
      border-top: 1px solid $panel-default-border;
      background: $panel-default-heading-bg;
    }
  }
  form.product {
    margin: 0px 0;
  }
}

.bundle-prices {
  border-bottom: 1px dotted rgba($brand-color-3, .3);
  margin-bottom: 20px;
  padding-bottom: 10px;
  label {
    display: block;
    border-bottom: 1px dotted rgba($brand-color-3, .3);

  }
}

ul.documents {
  list-style: none;
  padding: 0;
  a {
    &::before {
      content: '\f016';
      font-family: FontAwesome;
      font-size: 14px;
      margin-right: 5px;
    }
  }
}

a.LienPDF {
  &::before {
    content: '\f016';
    font-family: FontAwesome;
    font-size: 14px;
    margin-right: 5px;
  }
}

@media screen and (max-width: $screen-sm) {
  .art-item {
    .qty {
      text-align: center;
      max-width: 60px;
      margin: 0 auto 10px;

    }
  }
}

@media screen and (min-width: $screen-sm) {
  .alsoBought {
    padding: 0 10px !important;
  }
  .art-item {
    .qty {
      max-width: 80px;
    }
    .side-pic {
      float: right;
      margin-left: 15px;
      max-width: 35%;
      > img {
        max-width: 100%;
      }
    }
  }
  .art-li {
    &.blog {
      .pic {
        max-width: 35%;
        float: right;
        margin: 0 0 10px 30px;
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .art-item {
    .side-pic {
      float: right;
      max-width: 50%;
    }
  }
}